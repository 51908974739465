<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="发票明细"
    width="70%"
    destroy-on-close
  >
    <el-table
      :data="list"
      style="width: 100%"
      border
      height="450"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="业务类型" min-width="120" align="center">
        <template #default="scope">
          {{
            listQuery.type == "out"
              ? scope.row.subjectName
              : scope.row.businessName
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="taxName"
        label="税目"
        min-width="120"
        align="center"
        v-if="listQuery.type == 'out'"
      />
      <el-table-column label="开票项目" min-width="150" align="center">
        <template #default="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <template #content>
              <div>
                {{ scope.row.name
                }}{{ scope.row.remark ? "(" + scope.row.remark + ")" : "" }}
              </div>
            </template>
            <div class="box-content ccyc" style="text-align: left">
              {{ scope.row.name
              }}{{ scope.row.remark ? "(" + scope.row.remark + ")" : "" }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemName"
        label="补充项目"
        min-width="150"
        align="center"
      />
      <el-table-column prop="count" label="数量" width="90" align="center" />
      <el-table-column prop="amount" label="金额" width="90" align="center" />
      <el-table-column prop="taxRate" label="税率" width="90" align="center" />
      <el-table-column prop="tax" label="税额" width="90" align="center" />
      <el-table-column
        prop="total"
        label="价税合计"
        width="90"
        align="center"
      />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
          size="small"
        >
          确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { invoiceDetailInfoList } from "@/api/invoice";
const listQuery = ref({
  comId: 0,
  invoiceId: 0,
  type: "",
});
const list = ref([]);
const loading = ref(false);
const dialogVisible = ref(false);
const init = (item, id) => {
  listQuery.value.comId = id;
  listQuery.value.invoiceId = item.invoiceId;
  listQuery.value.type = item.types;
  dialogVisible.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  invoiceDetailInfoList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data[listQuery.value.type]
        ? res.data.data[listQuery.value.type]
        : [];
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
